import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Modal from "../modal/modal-transportation"

import "./hero.css"

const HeroRetail = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(relativePath: { eq: "retail-management.jpg" }) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1920
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-transportation"
        // preserveStackingContext
        style={{ backgroundPosition: "top center", height: "720px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="hero-custom-quickbase">
                <h1 className="page-title font-weight-medium mt-0 mb-4">
                  View and Manage Transportation Operations from a Single
                  Dashboard
                </h1>
                <p className="mb-0" style={{ paddingBottom: "20px" }}>
                  Every container you move through your port improves your
                  bottom line. We build systems to modernize port operations,
                  maximizing cargo efficiency and increasing speed to market.
                </p>
                <Modal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroRetail
